@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 50%;
}

/* mobile only */

@media (max-width: 768px) {
  input,
  textarea {
    /* prevents zooming on mobile */
    font-size: 2rem !important;
  }
}
html,
body {
  padding: 0;
  margin: 0;
  overscroll-behavior-x: none;

  /* this is used to make the selection background red */
  ::selection {
    background-color: #c41719;
    color: white;
  }

  ::-moz-selection {
    background-color: #c41719;
    color: white;
  }
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading), -apple-system, BlinkMacSystemFont, Segoe UI,
    sans-serif;
} */
a {
  color: default;
  text-decoration: none;
}
#blog-content a {
  color: blue;
  text-decoration: underline;
}
#blog-content a:visited {
  color: purple;
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}

/* Add pointer cursor to native search clear buttons */
input[type='search']::-webkit-search-decoration:hover,
input[type='search']::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

/* Hide input type number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Autocomplete bg & color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f4f4f4 inset !important;
  -webkit-text-fill-color: black !important;
}

input:focus {
  box-shadow: none !important;
}

textarea:focus {
  box-shadow: none !important;
  border: 2px solid #242d5f;
}

::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(30%) saturate(0%) hue-rotate(203deg) brightness(90%)
    contrast(95%);
  cursor: pointer;
}

::-webkit-calendar-picker-indicator:hover {
  filter: invert(60%) sepia(100%) saturate(1854%) hue-rotate(3deg)
    brightness(107%) contrast(106%);
}

.Typewriter__cursor {
  color: rgba(240, 98, 34, 1) !important;
}

.noScroll::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar {
  width: 1rem;
  padding-left: 10px;
  height: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 2rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #808080;
}

textarea {
  padding: 1.4rem 2rem;
  font-size: 1.5rem;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  /*remove the resize handle on the bottom right*/
}

/* CK Editor Config */
.ck-toolbar__items {
  flex-wrap: wrap !important;
}

.ck-content {
  background-color: inherit !important;
  width: 100% !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.ck-editor__editable {
  min-height: 20rem !important;
}

.ck-editor {
  width: 100%;
  max-width: 100% !important;
}

.ck.ck-reset.ck-editor {
  width: 100%;
  max-width: 100% !important;
}

.ais-Highlight-highlighted {
  background: none;
  font-weight: 600;
  /* color: #f06222; */
}

/* Scroll smooth doesn't work in safari so this prevents BaseProcess from breaking on safari */
.scroll-smooth-no-safari {
  scroll-behavior: smooth;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .scroll-smooth-no-safari {
      scroll-behavior: auto;
    }

    .overflow-clip {
      overflow: hidden !important;
    }
  }
}

.editor-content > blockquote,
.editor-content > dl,
.editor-content > dd,
.editor-content > h1,
.editor-content > h2,
.editor-content > h3,
.editor-content > h4,
.editor-content > h5,
.editor-content > h6,
.editor-content > hr,
.editor-content > figure,
.editor-content > p,
.editor-content > pre {
  margin: revert;
}
.editor-content > ol,
.editor-content > ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}
.editor-content > table {
  border-collapse: revert;
}

.editor-content h1 {
  font-size: 3.2rem;
  font-weight: 600;
}
.editor-content h2 {
  font-size: 2.4rem;
  font-weight: 600;
}
.editor-content h3 {
  font-size: 1.8rem;
  font-weight: 600;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* Category hover */

@keyframes bobFloat {
  100% {
    transform: translateY(-8px);
  }
}

@keyframes bob {
  0% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(-8px);
  }
}

.category-container:hover > img {
  animation-name: bobFloat, bob;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}

/* Progress bar styles */
progress.seller-progress::-moz-progress-bar {
  background: #c41719;
}
progress.seller-progress::-webkit-progress-value {
  background: #c41719;
}
progress.seller-progress {
  color: #c41719;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* react slider styles */
.thumb {
  @apply flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-brand-secondary text-lg text-white focus:outline-none;
  position: relative;
  top: -0.8rem;
  z-index: -1;
}

.track {
  @apply h-4 rounded bg-gray-300;
}

.track-0 {
  @apply bg-brand-secondary;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.snowflake {
  --size: 1vw;
  width: var(--size);
  height: var(--size);
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
}

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0);
  }
}

.snowflake:nth-child(1) {
  --size: 0.5vw;
  --left-ini: 10vw;
  --left-end: 15vw;
  left: 45vw;
  animation: snowfall 11s linear infinite;
  animation-delay: 10s;
}

/* added small blur every 6 snowflakes*/
.snowflake:nth-child(6n) {
  filter: blur(1px);
}

.holiday-banner {
  background-image: url('https://cdn.mxlocker.com/assets/banners/christmas-banner-mobile.webp');
}

@media screen and (min-width: 768px) {
  .holiday-banner {
    background-image: url('https://cdn.mxlocker.com/assets/banners/christmas-banner-1.webp');
  }
}

.shopping-guide {
  background-image: url('https://cdn.mxlocker.com/banners/new_year_mobile.webp');
}

@media screen and (min-width: 768px) {
  .shopping-guide {
    background-image: url('https://cdn.mxlocker.com/banners/new_year.webp');
  }
}

@media screen and (min-width: 768px) {
  .marquee {
    width: 100%;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
  }

  .marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 12s linear infinite;
  }

  /* Make it move */
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
}

.message-content > a {
  font: semibold;
  text-decoration: underline;
}

.product-viewer-text {
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.contain-layout {
  contain: layout;
}

.highlighted-search > mark {
  background-color: inherit;
  font-weight: 600;
}

.pb-safe {
  padding-bottom: env(safe-area-inset-bottom);
}
